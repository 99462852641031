import React from 'react';
import styled from 'styled-components';
import { breakpoints, Container } from '../../styles/layout';
import RawHTML from './RawHTML';
import FluidImg from './FluidImg';
import { rtypeN21bu, rtypeN18r, rtypeT38sb } from '../../styles/typography/presets';
import { colors } from '../../styles/colors';
import { DarkBlueOutlineButtonLink, PurpleOutlineButtonLink } from '../buttons/index';
import GradientImage from '../../assets/images/vertical_gradient.png';
import Rellax from 'rellax';
import TextBlock from './TextBlock';
import TextBlock2 from './TextBlock2';


const Title = styled.div`
  ${rtypeN21bu};
  color: ${colors.orange};
`;

const Message = styled.div`
  ${rtypeT38sb};
`;

const Brief = styled(RawHTML)`
  ${rtypeN18r};
  p {
    margin-bottom: 1.5em;
    &:last-child {
      margin-bottom: 0;
    }
  }
  em {
    font-style: italic;
  }
`;

const Button = styled(DarkBlueOutlineButtonLink)`

`;

const CustomTextBlock = styled(TextBlock)``;
const CustomTextBlock2 = styled(TextBlock2)``;

const Image = styled.div`
  border-radius: 6px;
  overflow: hidden;
`;

const Gradient = styled.div`
  img {
    max-width: 100%;
  }
`;

const Method = styled(Container)`
`;


const CustomContainer = styled.div`
  padding: 30px 0;

  ${Method} {
    grid-template-rows: 67px auto 33px auto;
    margin-bottom: 50px;
    ${CustomTextBlock}, ${CustomTextBlock2} {
      grid-column: 2 / span 8;
      grid-row: 4;

      ${Title} {
        margin-bottom: 12px;
      }

      ${Message} {
        margin-bottom: 12px;
      }
      ${Button} {
        margin-top: 30px;
      }
    }
    ${Image} {
      margin-bottom: 30px;
      grid-row: 2 / span 2;
    }
    ${Gradient} {
      z-index: -1;
      grid-row: 1 / span 2;
      display: ${props => props.hideGradient ? 'none' : 'block'};
    }
    &:nth-child(4n+1) {
      ${Image} {
        grid-column: 4 / span 6;
      }
      ${Gradient} {
        grid-column: 2 / span 3;
        img {
          transform: scaleX(-1);
        }
      }
    }
    &:nth-child(4n+2) {
      ${Image} {
        grid-column: 2 / span 6;
      }
      ${Gradient} {
        grid-column: 7 / span 3;
      }
    }
    &:nth-child(4n+3) {
      ${Image} {
        grid-column: 4 / span 6;
      }
      ${Gradient} {
        grid-column: 2 / span 3;
        img {
          transform: scale(-1);
        }
      }
    }
    &:nth-child(4n+4) {
      ${Image} {
        grid-column: 2 / span 6;
      }
      ${Gradient} {
        grid-column: 7 / span 3;
        img {
          transform: scale(1, -1);
        }
      }
    }
    &:nth-child(n) {
      ${Image} {
        ${props => props.hideGradient && `grid-column: 2 / span 8;`};
      }
    }
  }

  @media(max-width: ${breakpoints.lg}) {
    .rellax {
      transform: translate3d(0,0,0) !important;
    }
  }

  @media(min-width: ${breakpoints.lg}) {
    padding: 0;
    ${Method} {
      grid-template-rows: 120px auto 100px;
      align-items: center;
      margin-bottom: 0;

      ${CustomTextBlock}, ${CustomTextBlock2} {
        grid-row: 2;
        ${Title} {
          margin-bottom: 20px;
        }
        ${Message} {
          margin-bottom: 20px;
        }
        ${Button} {
          margin-top: 50px;
        }
      }
      ${Gradient} {
        grid-row: 1 / span 3;
      }
      ${Image} {
        grid-row: 2;
      }
      &:nth-child(4n+1) {
        ${Gradient} {
          grid-column: 2 / span 2;
        }
        ${Image} {
          grid-column: 3 / span 3;
          margin: 0 0 0 25px;
        }
        ${CustomTextBlock}, ${CustomTextBlock2} {
          grid-column: 7 / span 3;
        }
      }
      &:nth-child(4n+2) {
        margin-top: -100px;
        ${Gradient} {
          grid-column: 8 / span 2;
        }
        ${Image} {
          grid-column: 6 / span 3;
          margin: 0 25px 0 0;
        }
        ${CustomTextBlock}, ${CustomTextBlock2} {
          grid-column: 2 / span 3;
        }
      }
      &:nth-child(4n+3) {
        margin-top: -100px;
        ${Gradient} {
          grid-column: 2 / span 2;
        }
        ${Image} {
          grid-column: 3 / span 3;
          margin: 0 0 0 25px;
        }
        ${CustomTextBlock}, ${CustomTextBlock2} {
          grid-column: 7 / span 3;
        }
      }
      &:nth-child(4n+4) {
        margin-top: -100px;
        ${Gradient} {
          grid-column: 8 / span 2;
        }
        ${Image} {
          grid-column: 6 / span 3;
          margin: 0 25px 0 0;
        }
        ${CustomTextBlock}, ${CustomTextBlock2} {
          grid-column: 2 / span 3;
        }
      }
      &:last-child {
        margin-bottom: 80px;
      }
    }
  }
`;

export default class GradientBlocks extends React.Component {
  componentDidMount() {
    const { id } = this.props;
    setTimeout(() => {
      const selector = id ? `.${id}` : '.rellax';
      this.rellax = new Rellax(selector, {
        speed: 2,
        center: true,
      });
    }, 100);
  }

  render() {
    const { blocks, hideGradient, id, ...props } = this.props;

    return (
      <CustomContainer {...props} hideGradient={hideGradient} id={id || null}>
        {blocks.map((block, index) => (
          <Method key={index}>
            <Gradient className={id || 'rellax'} data-rellax-speed='1'>
              <img src={GradientImage}/>
            </Gradient>
            <Image className={id || 'rellax'}>
              <FluidImg
                image={block.image}
              />
            </Image>
            {block.secondary_message ? (
              <CustomTextBlock2
                title={block.title}
                message={block.message}
                secondaryMessage={block.secondary_message}
                brief={block.brief}
                secondaryBrief={block.secondary_brief}
                buttonLink={block.buttonLink}
                buttonText={block.buttonText}
              />
            ) : (
              <CustomTextBlock
                title={block.title}
                message={block.message}
                brief={block.brief}
                buttonLink={block.buttonLink}
                buttonText={block.buttonText}
              />
            )}
          </Method>
        ))}
      </CustomContainer>
    );
  }
}
