import React from 'react';
import styled from 'styled-components';
import { breakpoints, Container } from '../../styles/layout';
import RawHTML from './RawHTML';
import FluidImg from './FluidImg';
import { rtypeN21bu, rtypeN16r, rtypeN18r, rtypeT38sb, typeT21sb } from '../../styles/typography/presets';
import { colors } from '../../styles/colors';
import {
  DarkBlueOutlineButton,
  DarkBlueOutlineButtonLink,
  PurpleButtonStyle,
  PurpleOutlineButtonLink,
} from '../buttons/index';
import GradientImage from '../../assets/images/vertical_gradient.png';
import Rellax from 'rellax';
import { OrangeButtonStyle } from '../buttons';

const Title = styled.div`
  ${rtypeN18r};
  color: ${colors.orange};
`;

const SecondaryTitle = styled.div`
  ${rtypeN21bu};
  margin: 24px 0 16px;
`;

const Message = styled.div`
  ${rtypeT38sb};
`;

const Brief = styled(RawHTML)`
  ${rtypeN16r};
  p {
    margin-bottom: 1.5em;
    &:last-child {
      margin-bottom: 0;
    }
  }

`;

const ButtonLink = styled(DarkBlueOutlineButtonLink)``;
const SecondaryButtonLink = styled(DarkBlueOutlineButtonLink)``;

const Button = styled(DarkBlueOutlineButton)``;
const SecondaryButton = styled(DarkBlueOutlineButton)``;

const TextBlock = styled.div`
  ${Title} {
    margin-bottom: 12px;
    color: ${props => props.secondaryColor || 'inherit'};
  }

  ${Message} {
    margin-bottom: 12px;
  }
  ${ButtonLink}, ${Button} {
    margin-top: 30px;
    ${props => props.secondaryColor === colors.purple && PurpleButtonStyle};
    ${props => props.secondaryColor === colors.orange && OrangeButtonStyle};
  }
  ${SecondaryButtonLink}, ${SecondaryButton} {
    margin-top: 24px;
    ${props => props.secondaryColor === colors.purple && OrangeButtonStyle};
    ${props => props.secondaryColor === colors.orange && PurpleButtonStyle};
  }

  @media(min-width: ${breakpoints.lg}) {
    ${Title} {
      margin-bottom: 20px;
    }
    ${Message} {
      margin-bottom: 20px;
    }
    ${ButtonLink}, ${Button} {
      margin-top: 50px;
    }
    ${SecondaryButtonLink}, ${SecondaryButton} {
      margin-top: 16px;
      margin-left: 8px;
    }
  }
`;

export default ({ title, message, brief, button, buttonLink, buttonText, secondaryButtonLink, secondaryButtonText, secondaryColor, secondaryMessage, secondaryBrief, ...props }) => (
  <TextBlock secondaryColor={secondaryColor} {...props}>
    <Message>{title}</Message>
    <SecondaryTitle>{message}</SecondaryTitle>
    <Brief>{brief}</Brief>
    {secondaryMessage && <SecondaryTitle>{secondaryMessage}</SecondaryTitle>}
    {secondaryBrief && <Brief>{secondaryBrief}</Brief>}
    {buttonLink && buttonText && (
      <>
        {buttonLink.startsWith('/') ? (
          <ButtonLink to={buttonLink}>
            {buttonText}
          </ButtonLink>
        ) : (
          <a href={buttonLink} target={'_blank'}>
            <Button>
              {buttonText}
            </Button>
          </a>
        )}
      </>
    )}
    {secondaryButtonLink && secondaryButtonText && (
      <>
        {secondaryButtonLink.startsWith('/') ? (
          <SecondaryButtonLink to={secondaryButtonLink}>
            {secondaryButtonText}
          </SecondaryButtonLink>
        ) : (
          <a href={secondaryButtonLink} target={'_blank'}>
            <SecondaryButton>
              {secondaryButtonText}
            </SecondaryButton>
          </a>
        )}
      </>
    )}
  </TextBlock>
)
