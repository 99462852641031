import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layouts';
import Hero from '../components/shared/Hero';
import { Helmet } from 'react-helmet';
import { descriptionTags, imageTags, titleTags } from '../utilities/head-tags';
import GradientBlocks from '../components/shared/GradientBlocks';
import styled from 'styled-components';


const CustomGradientBlocks = styled(GradientBlocks)`
  padding: 30px 0;
`;

class ImpactPage extends React.Component {
  componentDidMount() {
    window.analytics && window.analytics.page('Make an Impact', {
      title: `Make an Impact | ${this.props.data.prismicSite.data.name}`,
      url: window.location.href,
      path: window.location.pathname,
    });
  }

  render() {
    const { data } = this.props;
    const pageData = data.prismicImpactPage.data;

    return (
      <Layout reverseLogo>
        <Helmet>
          {titleTags(`Make an Impact | ${data.prismicSite.data.name}`)}
          {descriptionTags(pageData.hero_brief.text)}
          {imageTags(pageData.hero_image.url)}
        </Helmet>
        <Hero
          title={pageData.hero_title}
          message={pageData.hero_message}
          brief={pageData.hero_brief.html}
          image={pageData.hero_image}
        />
        <CustomGradientBlocks
          blocks={pageData.methods.map(method => ({
            title: method.title,
            message: method.message,
            brief: method.brief.html,
            image: method.image,
            buttonText: method.button_text,
            buttonLink: method.button_link,
          }))}
        />
      </Layout>
    );
  }
}


export default ImpactPage;

export const query = graphql`
  query ImpactPageQuery {
    prismicSite {
      data {
        name
      }
    }
    prismicImpactPage {
      dataString
      data {
        hero_title
        hero_message
        hero_brief {
          html
          text
        }
        hero_image {
          alt
          copyright
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        
        methods {
          title
          message
          brief {
            html
          }
          button_text
          button_link
          image {
            alt
            copyright
            url
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, maxHeight: 1920, quality: 90, cropFocus: CENTER) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        footnote_message
      }
    }
  }
`;
